.caregiver-section {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 64px 0;
    align-items: center;
}

.caregiver-section h3 {
    font-weight: 600;
    margin-bottom: 64px !important;
}

.caregiver-section-inner {
    display: flex;
    width: 70%;
    justify-content: space-between;
}

.caregiver-left-section,
.caregiver-right-section {
    border: 1px solid #DDD7D7;
    border-radius: 9px;
    padding: 24px;
    width: 30%;
}

.caregiver-card {
    background-color: #FCFDFF;
    border: 1px solid #DDD7D7;
    border-radius: 9px;
    margin-bottom: 16px;
    padding: 16px;
}

.caregiver-card-inner {
    display: flex;
}

.caregiver-card-inner img {
    width: 57px;
    height: 57px;
    object-fit: cover;
    border-radius: 12px;
}

.caregiver-card-content,
.top-rated-caregiver-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 65%;
}

.caregiver-card-content .nanny-name,
.top-rated-caregiver-card-content .nanny-name {
    font-weight: 600;
    font-size: 18px;
}

.location {
    display: flex;
}

.location i {
    color: #642D92;
}

.location p {
    margin-left: 8px;
}

.rating {
    display: flex;
}

.rating i {
    color: #DE1877;
    margin-right: 8px;
}

.tags-section {
    display: flex;
    margin-top: 24px;
    align-items: center;
    justify-content: space-between;
}

.tags-section .tag,
.top-rated-caregiver-tags-inner .tag {
    background-color: rgba(100, 45, 146, 0.11);
    border: 1px solid #DDD7D7;
    border-radius: 9px;
    color: #642D92;
    padding: 8px;
    font-size: 12px;
    width: 35%;
    text-align: left;
}

.top-rated-caregiver-tags-inner .tag {
    width: 45% !important;
}

.tags-section p,
.top-rated-caregiver-rate {
    width: 20%;
    color: #642D92;
    font-size: 18px;
    font-weight: 600;
}

.top-rated-caregiver-rate {
    width: 100%;
    text-align: left;
}

.caregiver-left-section-bottom-title {
    text-align: left;
    font-size: 24px;
    width: 70%;
    font-weight: 400;
}

.caregiver-left-section-bottom-text {
    text-align: left;
    margin-top: 16px;
    width: 100%;
}

.caregiver-middle-section {
    width: 35%;
    border: 1px solid #DDD7D7;
    border-radius: 9px;
    padding: 24px;
}

.top-rated-caregiver-card {
    background-color: #FCFDFF;
    border: 1px solid #DDD7D7;
    border-radius: 9px;
    margin-bottom: 16px;
    padding: 16px;
}

.top-rated-caregiver-card-inner {
    display: flex;
}

.top-rated-caregiver-card-inner img {
    width: 116px;
    height: 109px;
    object-fit: cover;
    border-radius: 12px;
    margin-right: 16px;
}

.top-rated-caregiver-card .location {
    margin: 16px 0;
}

.top-rated-caregiver-tags,
.top-rated-caregiver-tags-inner {
    display: flex;
    justify-content: space-between;
}

.top-rated-caregiver-tags-inner {
    width: 60%;
}

.view-more-button {
    background-color: rgba(100, 45, 146, 0.11) !important;
    border: 1px solid #DDD7D7 !important;
    border-radius: 9px;
    color: #642D92 !important;
    padding: 8px;
    font-size: 12px;
}

.top-rated-caregiver-description {
    font-size: 18px;
    font-weight: 100;
    text-align: left;
    margin: 32px 0;
    width: 100%;
}

.caregiver-middle-section-title {
    text-align: left;
    margin-bottom: 16px !important;
    font-size: 18px;
    font-weight: 600;
}

.flickity-page-dots {
    bottom: -30px;
}

.flickity-page-dots .dot {
    background: #D1DCF3;
}

.flickity-page-dots .dot.is-selected {
    background-color: #642D92;
}

.caregiver-right-section-inner {
    padding: 16px;
    background-color: #FCFDFF;
    border: 1px solid #DDD7D7;
    border-radius: 9px;
}

.filter-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.14);
}

.filter-title {
    color: #232323;
    font-size: 20px;
    font-weight: 500;
}

.reset-filter-title {
    font-size: 20px;
    color: #EB1421;
    font-weight: 500;
}

.sister-type-title {
    font-weight: 600;
    text-align: left;
    margin-top: 16px;
}

.checkboxes-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.checkbox-item {
    display: flex;
    align-items: center;
    margin: 16px 0;
    width: 50%;
}

.checkbox-item label {
    margin-left: 5px;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-size: 14px;
    font-weight: initial;
    color: #555555;
}

.checkbox-item input {
    border: 1px solid #707070;
    width: 20px;
    height: 20px;
}

.salary-range, .amount-range {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}

.salary-range p:first-child {
    font-weight: bold;
    font-size: 16px;
}

.salary-range p:last-child, .amount-range p, .filter-location {
    border: 1px solid #DDD7D7;
    background-color: #FCFDFF;
    padding: 8px 16px;
    border-radius: 9px;
}

.amount-range p {
    padding: 8px 32px !important;
}

.filter-location {
    margin-top: 16px;
}

.caregiver-right-section-title {
    text-align: left;
    margin: 32px 0 !important;
    font-size: 22px;
    font-weight: 500;
}

.caregiver-right-section-description {
    text-align: left;
    font-size: 18px;
}

@media screen and (max-width: 768px) {
    .caregiver-section {
        flex-direction: column;
        padding: 32px 16px !important;
    }

    .caregiver-section h3 {
        margin-bottom: 16px !important;
    }

    .caregiver-section-inner {
        width: 100%;
        flex-direction: column;
    }

    .caregiver-left-section, .caregiver-middle-section, .caregiver-right-section {
        width: 100%;
    }

    .flickity-page-dots {
        bottom: -10px;
    }

    .caregiver-middle-section {
        margin: 16px 0 !important;
    }
}