.team-section-container {
    width: 100%;
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-section-container h3 {
    font-weight: 600;
}

.team-section-container hr {
    border-radius: 2px;
    background-color: #C3C3C3;
    width: 30px;
    height: 4px;
    margin: 32px 0 !important;
}

.team-section-description {
    width: 50%;
    color: #555555;
    font-size: 18px;
}

.team-card-section {
    display: flex;
    width: 80%;
    margin-top: 64px;
    justify-content: space-between;
}

.team-card {
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
    width: 22%;
}

.team-card img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    object-position: top;
    border-radius: 5px 5px 0 0;
}

.team-card-caption p:first-child {
    font-weight: 600 !important;
    font-size: 18px !important;
    text-align: left !important;
    margin: 16px !important;
}

.team-card-caption p:last-child {
    margin: 16px 16px 32px 16px !important;
    text-align: left;
    font-size: 12px;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .team-section-container {
        padding: 32px 16px 16px 16px !important;
    }

    .team-section-description {
        width: 100%;
    }

    .team-section-container hr {
        margin: 16px 0 !important;
        width: 30%;
    }

    .team-card-section {
        flex-direction: column;
        margin-top: 16px !important;
        width: 100%;
    }

    .team-card {
        width: 100%;
        margin-bottom: 16px;
    }

    .team-card-caption p:first-child {
        margin: 8px !important;
    }

    .team-card-caption p:last-child {
        margin: 8px 8px 16px 8px !important;
    }

    .team-card img {
        height: 300px;
    }
}