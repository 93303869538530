/* Logout.css */
.logout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height for centering */
  margin-top: 0px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay for better contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of everything */
}

.modal {
  background-color: #fff; /* White background for the modal */
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

.modalTitle {
  margin-bottom: 20px;
  font-size: 20px; /* Increase the font size */
  font-weight: bold; /* Ensure it is bold */
  color: #333; /* Dark color for better contrast */
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
}

.confirmButton,
.cancelButton {
  padding: 10px 15px; /* Slightly smaller padding */
  font-size: 16px; /* Keep font size consistent */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effects */
}

.confirmButton {
  color: #fff; /* White text for contrast */
  background-color: #c14955; /* Confirm button color */
}

.confirmButton:hover {
  background-color: #a03a47; /* Darker shade on hover */
}

.cancelButton {
  color: #fff;
  background-color: #6c757d; /* Gray cancel button color */
}

.cancelButton:hover {
  background-color: #5a6268; /* Darker shade on hover */
}
