.features-container {
    width: 100%;
    display: flex;
    background-color: #F7F9FD;
    justify-content: center;
    padding-top: 64px;
    padding-bottom: 64px;
}

.features-container-inner {
    display: flex;
    width: 70%;
}

.features-intro {
    width: 50%;
    padding-right: 100px;
}

.features-intro h3 {
    font-weight: 600;
    text-align: left;
}

.features-intro-text {
    text-align: left;
    margin-top: 32px !important;
    margin-bottom: 32px !important;
}

.features-list-left-item {
    display: flex;
    margin: 32px 0;
    text-align: left;
}

.features-list-left-item i {
    color: #6F6F6E;
    margin-right: 8px;
}

.features-list-right-items {
    width: 50%;
}

.features-list-right-item {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.features-list-item-group {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
}

.features-list-item-group:nth-child(even) {
    margin-left: 16px;
}

.features-list-item-group img {
    background-color: white;
    padding: 16px;
    border: 1px solid #642D92;
    border-radius: 8px;
}

.features-list-item-group h3 {
    font-weight: 600;
    margin: 16px 0 !important;
    text-align: left;
    min-height: 64px;
}

.features-list-item-group p {
    text-align: left;
}

.features-list-right-item:nth-child(2), .features-list-right-item:nth-child(3) {
    margin-top: 32px;
}

@media (max-width: 768px) {
    .features-container {
        padding: 32px 16px;
    }

    .features-container-inner {
        width: 100%;
        flex-direction: column;
    }

    .features-intro {
        padding-right: 0;
        width: 100%;
    }

    .features-intro-text {
        margin-top: 16px !important;
        margin-bottom: 16px !important;
    }

    .features-list-left-item {
        margin: 16px 0 !important;
    }

    .features-list-right-items {
        width: 100%;
    }

    .features-list-item-group h3 {
        margin: 8px 0 !important;
    }
}