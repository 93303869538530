/* Centering login container */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f1f5f9;
}

/* Styling the login box */
.login-box {
    /* background-color: #ffffff; */
    padding: 40px;
    border-radius: 10px;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
    width: 100%;
    max-width: 450px;
    display: flex;
    /* Add flex display to center logo and title */
    flex-direction: column;
    /* Stack elements vertically */
    align-items: center;
    /* Center child elements horizontally */
}

/* Title styles */
.login-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-align: center;
}

/* Form group styles */
.form-group {
    margin-bottom: 20px;
}

/* Label styles */
.form-label {
    display: block;
    margin-bottom: 8px;
    color: #555555;
}

/* Input field styles */
.form-input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.2s ease-in-out;
}

/* Input hover and focus effects */
.form-input:focus {
    border-color: #007bff;
}

.login-form-button,
.reset-form-button {
    width: 100% !important;
    border-radius: 40px;
    padding: 16px 0 !important;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    transition: .5s ease-in linear;
    background-color: #DE1877 !important;
}

.login-form-button:hover,
.reset-form-button:hover {
    background-color: #642D92 !important;
}

.reset-form-button:disabled {
    background-color: #aaa !important;
}

/* Responsive for small devices */
@media (max-width: 600px) {
    .login-box {
        padding: 30px;
    }

    .login-title {
        font-size: 20px;
    }
}

.login-section-container {
    display: flex;
    width: 100%;
    padding: 16px;
}

.login-left-container {
    height: calc(100vh - 32px);
    width: 45%;
    background-color: #F2F4F8;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    justify-content: space-between;
}

.login-logo-image {
    width: 100px;
}

.login-left-title {
    text-transform: uppercase;
    width: 70%;
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    margin: 64px 0 !important;
    color: #642D92;
}

.employer-details {
    background-color: #642D92;
    border-radius: 21px;
    display: flex;
    flex-direction: column;
    padding: 32px;
}

.employer-details-text {
    color: white;
    text-align: left;
    font-size: 18px;
}

.employer-image-container {
    display: flex;
    margin-top: 32px;
}

.employer-details img {
    width: 57px;
    height: 57px;
    object-fit: cover;
    border-radius: 12px;
}

.logo-description {
    font-size: 18px;
    width: 70%;
    text-align: left;
    margin-bottom: 64px !important;
}

.employer-name {
    margin-left: 32px;
}

.employer-name p {
    color: white;
    text-align: left;
    font-size: 18px;
}

.employer-name p:first-child {
    font-weight: 600;
}

.login-help-text {
    margin-bottom: 32px !important;
}

.login-right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
    justify-content: center;
}

.login-bottom-section {
    display: flex;
    justify-content: space-between;
    width: inherit;
    margin-top: 32px;
}

.login-bottom-section a {
    text-decoration: none;
}

.email-invalid-error-message {
    margin: 0 !important;
}

.reset-password-title,
.reset-error-message,
.reset-success-message {
    margin-bottom: 32px !important;
}

.reset-error-message {
    color: red;
}

.reset-success-message {
    color: green;
}

.code-submission-form {}

.register-form-container {
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.register-form-container h3 {
    font-weight: 600 !important;
}

.register-help-text {
    margin-top: 32px !important;
}

.register-form-container div form button {
    margin-top: 32px !important;
}

.code-submission-container {
    padding: 0 32px;
}

.code-submission-container h3 {
    font-weight: 600 !important;
    width: 100%;
    text-align: left;
    margin-bottom: 32px !important;
}

.verify-code-image {
    background-image: url('../../public/img/verify-code.png');
    width: inherit;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 768px) {
    .login-section-container {
        flex-direction: column;
        padding-top: 16px !important;
    }

    .login-left-container {
        width: 100%;
        justify-content: normal;
        height: auto;
        padding: 16px !important;
    }

    .login-left-title {
        width: 100%;
        margin: 16px 0 !important;
    }

    .logo-description {
        width: 100%;
        margin-bottom: 16px !important;
    }

    .employer-details {
        padding: 16px !important;
    }

    .login-right-container {
        width: 100%;
    }

    .register-form-container h3 {
        margin-top: 16px;
    }

    .register-form-container {
        padding: 0;
    }

    .select-photo-container {
        margin-bottom: 16px;
    }

    .register-form-container div form button {
        margin-top: 16px !important;
    }
}