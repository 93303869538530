.footer-top-section-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #001648;
    padding: 64px 0;
    align-items: center;
}

.footer-top-section-inner {
    display: flex;
    width: var(--inner-container-width);
    justify-content: space-between;
}

.far-left-section, .mid-left-section, .far-right-section {
    display: flex;
    flex-direction: column;
    width: 23%;
}

.far-left-section-description {
    color: #9CA6BF !important;
    text-align: left;
    margin-top: 32px;
}

.logo {
    width: 150px;
}

.footer-contact-info {
    margin-top: 32px;
    display: flex;
}

.footer-contact-info i {
    color: #9CA6BF;
}

.footer-contact-info p {
    color: white !important;
    margin-left: 8px;
}

.mid-left-section p:first-child, .mid-right-section p:first-child, .far-right-section p:first-child {
    font-size: 18px;
    color: white !important;
    margin-bottom: 32px !important;
    text-align: left;
}

.mobile-app-link {
    text-decoration: none;
    background-color: #000000;
    display: flex;
    color: white;
    padding: 16px 32px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.mobile-app-link i {
    font-size: 24px;
}

.mobile-app-link p {
    margin-left: 16px;
}

.mobile-app-link:last-child {
    margin-top: 32px;
}

.mid-right-section li {
    display: flex;
    justify-content: flex-start;
}

.mid-right-section a {
    text-decoration: none;
    color: #9CA6BF;
    transition: .5s linear ease-in;
    padding-bottom: 8px;
}

.mid-right-section a:hover {
    text-decoration: underline;
}

.far-right-section {
    justify-content: flex-start;
}

.far-right-section form {
    text-align: left;
}

.far-right-section input {
    width: 100% !important;
}

.far-right-section input:last-child {
    margin: 32px 0 !important;
}

.far-right-section button {
    background-color: white !important;
    color: #001A57 !important;
    transition: .5s linear ease-in;
    border-radius: 5px !important;
    font-size: 14px !important;
    padding: 16px 32px !important;
    font-weight: 500;
}

.email-address-input-field {
    margin: 32px 0 !important;
}

.name-input-field, .email-address-input-field {
    padding: 16px;
}

@media screen and (max-width: 768px) {
    .footer-top-section-container {
        padding: 32px 16px !important;
    }

    .footer-top-section-inner {
        width: 100%;
        flex-direction: column;
    }

    .far-left-section, .mid-left-section, .far-right-section {
        width: 100%;
    }

    .far-left-section, .mid-left-section, .mid-right-section {
        align-items: center !important;
    }

    .far-left-section-description, .footer-contact-info {
        margin-top: 16px;
    }

    .mid-left-section p:first-child, .mid-right-section p:first-child, .far-right-section p:first-child {
        margin: 16px 0 !important;
        text-align: center;
    }

    .mid-right-section ul {
        margin-bottom: 0 !important;
    }

    .mobile-app-link {
        width: 100%;
    }

    .far-right-section input {
        margin-bottom: 16px !important;
        padding: 8px !important;
    }

    .far-right-section button {
        width: 100%;
    }

    .email-address-input-field {
        margin: 16px 0 !important;
    }
}