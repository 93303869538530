.search-container {
    /* position: absolute; */
    /* bottom: 60px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-100px);
}

.search-container-inner {
    background-color: #F6F6F6;
    border-bottom: 4px solid #642D92;
    padding: 48px;
    width: 70%;
}

.search-dropdown-input, .search-location-input {
    background-color: #FCFDFF;
    border: 1px solid #DDD7D7;
    border-radius: 9px;
    display: flex;
    width: 47%;
    padding: 16px;
}

.search-dropdown-input {
    justify-content: space-between;
}

.search-dropdown-input p, .search-location-input p {
    color: #555555;
}

.search-dropdown-input i, .search-location-input i {
    color: #8A8D9F;
}

.search-section {
    display: flex;
    width: 100%;
  justify-content: space-between;
}

.search-location-input p {
    margin-left: 16px;
}

.search-icon {
    background-color: #642D92;
    border: 1px solid #DDD7D7;
    border-radius: 9px;
    padding: 16px;
    color: white;
    cursor: pointer;
    transition: .5s linear ease-in;
}

.search-icon:hover {
    background-color: rgba(229, 223, 235, 0.3) !important;
    color: #642D92;
}

.buttons-section-title {
    font-size: 26px;
    text-align: left;
    margin-top: 24px;
}

.button-items {
    display: flex;
}

.button-items .best-services-chip {
    width: 20% !important;
    margin-right: 24px !important;
}

.more-button {
    background-color: rgba(229, 223, 235, 0.3) !important;
    color: #642D92;
    border-radius: 9px;
    border: 1px solid #DDD7D7;
    transition: .5s solid ease-in;
}

.more-button:hover {
    background-color: #642D92 !important;
    color: white;
}

.clickable-icon {
    cursor: pointer;
}

@media (max-width: 768px) {
    .search-container-inner {
        width: 100%;
        padding: 16px;
    }

    .search-container {
        padding: 0 16px;
    }

    .search-section {
        flex-direction: column;
    }

    .search-dropdown-input, .search-location-input {
        width: 100%;
    }

    .search-location-input, .search-icon, .buttons-section-title {
        margin-top: 16px;
    }

    .buttons-section-title {
        margin-bottom: 16px !important;
    }

    .button-items {
        flex-wrap: wrap;
        row-gap: 16px;
        justify-content: space-between;
    }

    .button-items .best-services-chip {
        width: 45% !important;
        margin-right: 0 !important;
    }
}