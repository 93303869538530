.profile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f3f0f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 90px;
  
}

.profile-title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
  color: #4b306a; /* Dark purple for the title */
}

.profile-section {
  margin-bottom: 20px;
  border: 1px solid #d3cce3; /* Light purple border */
  border-radius: 8px;
  overflow: hidden;
}

.section-header {
  background-color: #e5d8f5; /* Light purple background */
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.section-header h2 {
  margin: 0;
  font-size: 1.5em;
  color: #4b306a; /* Dark purple for section headers */
}

.section-content {
  padding: 15px;
  background-color: #ffffff;
}

.section-content p,
.section-content strong {
  margin: 10px 0;
  color: #555; /* Dark gray text */
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  margin-bottom: 10px;
}

button {
  background-color: #7b4ead; /* Main purple button color */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #5e378a; /* Darker purple on hover */
}

.work-experience-list {
  list-style-type: none;
  padding: 0;
}

.work-experience-list li {
  margin: 10px 0;
  border-bottom: 1px solid #d3cce3; /* Light purple border */
  padding: 10px 0;
}

.add-experience-btn {
  background-color: #a37fba; /* Light purple button for additional actions */
  margin-top: 10px;
}

.add-experience-btn:hover {
  background-color: #7b4ead; /* Darker purple on hover */
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.modal-content h2 {
  margin-top: 0;
  color: #4b306a; /* Dark purple for modal header */
}

.modal-content button {
  margin-top: 10px;
}

.upload-button {
  margin-top: 0.5rem;
  cursor: pointer;
}

.disabled-button {
  filter: blur(3px); /* Adds a blur effect */
  pointer-events: none; /* Prevents clicking */
  opacity: 0.5; /* Optionally, make it semi-transparent */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
}

.styledDiv {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  background-color: #f9f9f9;
  min-height: 40px;
  font-size: 14px;
  line-height: 20px;
}

.profile-picture-container {
  text-align: center; /* Center align the image */
}

.profile-picture {
  width: 30%; /* Default to 50% width on larger screens */
  height: auto; /* Maintain aspect ratio */
  border-radius: 20px; /* Rounded corners */
  transition: width 0.3s ease; /* Smooth transition for width change */
}

@media (max-width: 768px) {
  .profile-picture {
    width: 100%; /* 100% width on screens smaller than 768px */
  }
}