.modals {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  /* Limit size on large screens */
  width: 90%;
  /* Allow some flexibility for smaller screens */
  z-index: 10000;
  overflow-y: auto;
  max-height: 80vh;
  /* Keep maximum height to 80% of viewport height */
  margin-top: 50px;
}

h2 {
  margin-bottom: 20px;
  color: #333;
}

/* label {
  display: block;
  margin: 10px 0 5px;
  font-weight: bold;
  color: #555;
} */

/* input[type="text"],
input[type="number"],
input[type="date"],
input[type="email"],
select,
textarea {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 16px;
  transition: border-color 0.3s ease;
} */


button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:last-of-type {
  background-color: #642D92;
}

button:last-of-type:hover {
  background-color: #642D92;
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 10px;
}

h4 {
  margin: 15px 0 5px;
  color: #333;
}

/* div {
    margin-bottom: 15px;
  } */

textarea {
  resize: vertical;
  height: 100px;
}

.additionalInfoParagraph {
  font-weight: bold;
}

.service-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

}

.service-item input {
  margin-right: 10px;
}

.service-item label {
  /* font-size: 15px; */
  color: #333;
}


/* Responsive Design */
@media (max-width: 768px) {
  .modals {
    padding: 15px;
    /* Less padding for smaller screens */
    max-height: 90vh;
    /* Increase max-height for smaller screens */
  }

  input[type="text"],
  input[type="number"],
  input[type="date"],
  select,
  textarea {
    font-size: 14px;
    /* Smaller font size for better fit */
  }

  button {
    font-size: 14px;
    /* Smaller button text */
    padding: 8px 12px;
    /* Smaller padding for buttons */
  }
}

@media (max-width: 480px) {
  .modals {
    max-width: 95%;
    /* Even wider for very small screens */
  }

  h2 {
    font-size: 20px;
    /* Smaller heading */
  }
}