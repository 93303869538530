.dashboard-container {
    max-width: 100% !important;
}

.employer-dashboard-page, .employee-dashboard-page {
    background-color: #F5F6F7;
    border-radius: 20px;
    padding: 32px;
}

.employer-card, .employee-card {
    width: 30%;
    /* border: 1px solid #ccc; */
    padding: 24px;
    background-color: white;
    border-radius: 16px;
}

.employers-section, .employees-section {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.avatar-img {
    width: 40px !important;
    height: 40px !important;
    border-radius: 100px !important;
    object-fit: cover;
    object-position: center;
}

.job-card {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 16px;
    flex-grow: 1;
}

.skill-icon {
    width: 24px !important;
    height: 24px !important;
    color: white !important;
}

.skill-icon-bg {
    background-color: #448BE6;
    width: fit-content;
    padding: 16px;
    border-radius: 16px;
}

.role-text {
    font-weight: 600 !important;
    font-size: 20px;
    text-align: left;
    margin: 16px 0;
}

.job-details-section {
    margin: 16px 0;
}

.job-details-section p {
    text-align: left;
}

.add-blog-section {
    display: flex;
    flex-direction: column;
}

.add-blog-section input:first-child {
    padding: 8px;
    margin-top: 16px;
} 

.add-blog-section textarea {
    margin: 16px 0;
    width: 100% !important;
}

@media screen and (max-width: 768px) {
    .employers-section, .employees-section {
        flex-direction: column;
    }

    .employer-card, .employee-card {
        width: 100%;
    }

    .filter-section {
        justify-content: center !important;
    }

    .inputs-section input:last-child {
        margin-top: 16px;
    }
}