/* Styles for the NavBar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px; /* Increase padding to make the navbar larger */
    background-color: #642D92; /* Purple background */
    color: white;
    position: fixed; /* Makes it float */
    top: 0;
    left: 0;
    right: 0;
    z-index: 999; /* Ensure it's on top */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Floating shadow effect */
}

.navbar-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
    height: fit-content;
}

.navbar-container-inset {
    width: 70%;
}

.navbar-header {
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
}

.navbar-help-info-container {
    display: flex;
    align-items: center;
}

.navbar-help-info-container .info-item {
    display: flex;
    height: fit-content;
    align-items: center;
}

.navbar-help-info-container .info-item .info-item-text {
    text-align: left;
    margin-left: 16px;
}

.navbar-help-info-container .info-item .info-item-text p:first-child {
    font-weight: 500;
    color: #232323 !important;
}

.navbar-help-info-container .info-item .info-item-text p:last-child {
    font-weight: 300;
    color: #555555;
}

.navbar-help-info-container .separator {
    height: 75%;
    width: 1px;
    background-color: #E8E8E8;
    margin: 0 16px;
}

.navbar-links-container {
    background-color: #642D92;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.navbar-links-container button {
    height: 72px;
    color: white !important;
    background-color: #000F32 !important;
    transition: 1s linear ease-in;
}

.navbar-links-container button:hover {
    background-color: black !important;
}

.navbar-links {
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: space-between;
    /* padding: 24px 0; */
}

.navbar-links a {
    padding: 0 !important;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    color: #8F97AB !important;
    text-decoration: none;
    transition: 1s linear ease-in;
}

.navbar-links a:hover {
    /* color: white !important; */
    text-decoration: underline;
}

.navbar-links a.active {
    color: white !important;
}

.navbar-links a:first-child {
    margin-left: 32px;
}

.navbar-links span {
    margin: 0 24px;
    color: white;
}

.login-button {
    background-color: transparent !important;
    color: #DE1877 !important;
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    margin-right: 32px;
}

.register-button {
    background-color: #DE1877 !important;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 400;
    padding-left: 32px !important;
    padding-right: 32px !important;
}

/* Adjust logo size */
.logo-image {
    height: 40px; 
    width: auto; 
}

/* Adding a bit of margin-top to the content so it doesn't overlap the navbar */
.dashboard-content {
    margin-top: 80px; /* Adjust as necessary */
}

/* Hamburger menu toggle */
.navbar-toggle {
    display: none; /* Hidden by default */
    cursor: pointer; /* Pointer cursor */
}

/* Responsive styles */
@media (max-width: 768px) {
    .navbar-toggle {
        display: block;
    }

    .navbar-container-inset {
        width: 100%;
    }

    .navbar-header {
        flex-direction: column;
        padding: 0;
    }

    .navbar-logo {
        height: 70px;
        object-fit: contain;
    }

    .navbar-help-info-container {
        padding: 16px;
    }

    .navbar-top-bar-links {
        width: 100%;
        justify-content: space-between !important;
        padding: 16px;
    }

    .navbar-links {
        width: 100%;
        flex-wrap: wrap;
    }

    .navbar-links-container {
        padding: 16px 0 0 0 !important;
    }

    .post-job-button {
        width: 100%;
        margin-top: 16px;
    }
}

/* Styles for modal overlay */
.modal-overlay {
    position: fixed; /* Fixed so it covers the screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is on top of everything */
}

/* Optional: styling for Logout popup itself */
.logout-popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1001; /* Ensure it's on top of overlay */
    max-width: 300px;
    text-align: center;
}
