.services-container {
    margin-top: -40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-bottom: 64px;
}

.services-container-inner {
    width: var(--inner-container-width);
    display: flex;
}

.services-intro {
    width: 30%;
    text-align: left;
    align-content: flex-end;
}

.services-intro p:first-child {
    font-size: 18px;
    font-weight: bold;
}

.services-intro p:last-child {
    margin-top: 16px;
}

.scroll-down {
    width: 20%;
    align-content: center;
    /* margin: 0 16px; */
    /* margin-top: 16px; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

.scroll-down a {
    border: 1px solid #DDD7D7;
    border-radius: 9px;
    background-color: #FCFDFF !important;
    color: #555555 !important;
    display: flex;
    align-items: center;
    padding: 16px 8px;
    width: fit-content;
    text-decoration: none;
}

.scroll-down a i {
    font-size: 14px;
    margin-left: 8px;
}

.best-services-section {
    width: 50%;
}

.best-services-section h3 {
    font-weight: 700;
    text-align: left;
    /* margin-top: 16px; */
}

.best-services {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.best-services-chip {
    background-color: #FCFDFF;
    border: 1px solid #DDD7D7;
    padding: 8px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    text-align: left;
    width: 30%;
    font-size: 14px;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .services-container {
        margin-top: -80px;
        padding-bottom: 32px;
    }

    .services-container-inner {
        width: 100%;
        padding: 0 16px;
        flex-direction: column;
    }

    .services-intro {
        width: 100%;
    }

    .scroll-down {
        width: 100%;
        align-items: flex-start;
        margin: 16px 0 !important;
    }

    .best-services-section {
        width: 100%;
    }

    .best-services-section h3 {
        font-size: 18px;
    }

    .best-services {
        margin-top: 16px;
    }
}