.contact-us-section-container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    padding: 64px 0 !important;
    background: #F7F9FD !important;
    align-items: center;
}

.contact-us-section-container h3 {
    font-weight: 600;
}

.contact-us-section-container hr {
    border-radius: 2px;
    background-color: #C3C3C3;
    width: 30px;
    height: 4px;
    margin: 32px 0 !important;
}

.contact-us-section-description {
    width: 50%;
    color: #555555 !important;
    font-size: 18px;
}

.maps-and-contact-form {
    display: flex;
    width: 70%;
    box-shadow: 0px 0px 30px #00000014;
    margin-top: 64px;
}

.map-image {
    width: 50%;
    height: inherit;
    object-fit: cover;
    object-position: center;
    border-radius: 5px 0 0 5px !important;
}

.contact-form {
    width: 50%;
    border-radius: 0 5px 5px 0;
    padding: 64px 32px;
    background-color: #FFFFFF;
}

.contact-form-title {
    margin-bottom: 32px !important;
}

.contact-form-description {
    margin-bottom: 32px !important;
}

.contact-form button {
    margin-top: 32px;
    border-radius: 5px;
    background-color: #DE1877 !important;
    transition: .5s linear ease-in;
}

.contact-form button:hover {
    background-color: #642D92 !important;
}

.name-email-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact-form textarea {
    width: 100% !important;
}

.name-email-section input,
textarea {
    width: 49% !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #DFDFDF;
}

.textarea {
    margin: 64px !important;
}

input:focus-visible,
textarea:focus-visible {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.contact-info {
    width: 70%;
}

.contact-info-title {
    margin-top: 64px;
}

.contact-info-items {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 32px;
}

.contact-info-item {
    display: flex;
    width: 40%;
    justify-content: center;
}

.contact-info-item i {
    color: #642D92;
}

.contact-info-item p {
    margin-left: 8px;
    color: #333;
}

.contact-info-item a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .contact-us-section-container {
        padding: 32px 16px !important;
    }

    .contact-us-section-container hr {
        width: 30%;
        margin: 16px 0 !important;
    }

    .contact-us-section-description {
        width: 100%;
    }

    .maps-and-contact-form {
        margin-top: 16px !important;
        width: 100%;
        flex-direction: column;
    }

    .map-image, .contact-form {
        width: 100%;
    }

    .contact-form {
        padding: 32px 16px !important;
    }

    .contact-form-title, .contact-form-description {
        margin-bottom: 16px !important;
    }

    .contact-form button {
        margin-top: 16px !important;
    }

    .contact-info {
        width: 100%;
    }

    .contact-info-title {
        margin-top: 32px !important;
    }

    .contact-info-items {
        margin-top: 16px;
        flex-direction: column;
    }

    .contact-info-item {
        width: 100%;
    }

    .contact-info-item:nth-child(2) {
        margin: 16px 0;
    }
}
