.blog-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 64px 0;
    align-items: center;
    background-color: white;
}

.blog-section-inner {
    display: flex;
    width: 70%;
    justify-content: space-between;
}

.blog-section-left {
    width: 60%;
    text-align: left;
}

.blog-section-title {
    font-weight: 600;
}

.blog-section-description {
    margin-top: 64px;
    font-size: 24px;
    font-weight: initial;
    width: 60%;
}

hr {
    width: 60%;
    height: 1px !important;
    background-color: #707070;
    margin-top: 64px !important;
}

.quote-section {
    display: flex;
    align-items: center;
    margin-top: 32px;
}

.quote {
    width: 80px;
    height: 80px;
    border-radius: 16px;
    background-color: #D3DFF5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    letter-spacing: -1px;
    font-size: 32px;
}

.quote-details {
    margin-left: 32px;
}

.quote-details p:first-child {
 font-size: 20px;
 font-weight: 600;   
}

.quote-details p:last-child {
    font-size: 18px;
    font-weight: 200;
}

.blog-section-right {
    position: relative;
}

.blog-section-right img {
     height: 80%;
    /* object-fit: cover; */
    /* width: 90%;  */
    border-radius: 12px;
}

.play-button {
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    transform: translateX(-60px);
    margin-bottom: 32px;
    margin-right: 32px;
    border-radius: 1000px;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.play-button i {
    color: #642D92;
}

@media screen and (max-width: 768px) {
    .blog-section {
        padding: 32px 16px !important;
    }

    .blog-section-inner {
        flex-direction: column;
        width: 100%;
    }

    .blog-section-left {
        width: 100%;
    }

    .blog-section-description {
        margin-top: 16px !important;
        width: 100%;
        font-size: 18px !important;
    }

    hr {
        width: 100%;
        margin: 16px 0 !important;
    }

    .quote-section {
        width: 100%;
        justify-content: flex-start;
        margin-top: 16px !important;
    }

    .blog-section-right {
        width: 100%;
        margin-top: 16px;
    }

    .blog-section-right img {
        width: 100%;
    }

    .play-button {
        transform: translateX(0px);
    }
}