  
  .status-filter {
    margin-bottom: 20px;
  }
  
  .notification-list {
    list-style: none;
    padding: 0;
  }
  
  .notification-list li {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .notification-list li.unread {
    font-weight: bold;
    background-color: #f0f8ff;
  }
  
  .notification-list li.read {
    background-color: #f9f9f9;
  }
  