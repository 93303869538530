.testimonials-container {
    width: 100%;
    padding: 64px 0;
    background-color: #fff;
}

.testimonials-container-inner {
    margin-left: 15%;
    display: flex;
}

.testimonials-container-inner h3 {
    font-weight: 600;
    margin-bottom: 20px;
}

.testimonials-cards-container {
    margin-top: 64px;
    width: 100%;
    margin-left: 5%;
}

.review-card {
    background-color: #FFFFFF;
    box-shadow: 0.16px 3px 26px #E4E4E4BF;
    padding: 48px 32px;
    border: 1px solid #DDD7D7;
    border-radius: 4px;
    margin-left: 15px;
    width: 30%;
}

.reviewer-details {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.reviewer-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f0f0f0;
    margin-right: 16px;
}

.reviewer-name-role {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.reviewer-name-role p:first-child {
    font-weight: 600;
}

.reviewer-name-role p:last-child {
    font-weight: 200;
}

.review {
    text-align: left;
    font-size: 0.95rem;
    line-height: 1.6;
    color: #555;
}

@media screen and (max-width: 768px) {
    .testimonials-container {
        padding: 0px 16px 32px 16px !important;
    }

    .testimonials-container-inner {
        width: 100%;
        margin-left: 0 !important;
    }

    .review-card {
        width: 100%;
    }

    .testimonials-cards-container {
        margin-left: 0 !important;
        margin-top: 32px !important;
    }
}