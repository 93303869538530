/* Main container */
.terms-container {
  /* max-width: 90%;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 80px; */
  width: 100%;
  padding: 32px;
  text-align: left;
  background-color: #f9f9f9;
}

/* Header */
.terms-header {
  text-align: center;
  margin-bottom: 32px;
}

.terms-header h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 16px !important;
  color: #333;
}

/* Terms content section */
.terms-content {
  padding: 32px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 9px;
}

.terms-content p:first-child {
  margin-bottom: 16px !important;
}

.terms-content h4 {
  margin: 16px 0 !important;
}

.term {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.term-icon {
  font-size: 20px;
  color: #642D92;
  margin-right: 10px;
}

.term p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

/* Footer with the Back button */
.terms-footer {
  text-align: center;
  margin-top: 20px;
}

.terms-footer .MuiButton-root {
  background-color: #00796b;
  color: #fff;
  padding: 10px 20px;
  text-transform: none;
}

.terms-footer .MuiButton-root:hover {
  background-color: #004d40;
}
