.caregiver-list-section {
    background-color: #F7F9FD;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 64px 0;
    align-items: center;
}

.caregiver-list-section-inner {
    display: flex;
    width: 70%;
    justify-content: space-between;
    flex-direction: column;
}

.caregiver-list-title-section {
    display: flex;
    margin-bottom: 32px;
    justify-content: space-between;
}

.caregiver-list-title {
    width: 30%;
    text-align: left;
    font-weight: 600;
}

.caregiver-list-title-description {
    font-weight: initial;
}

.services-chips-section {
    display: flex;
    gap: 0 32px;
    justify-content: space-between;
}

.services-chips-section .best-services-chip {
    width: initial;
}

.caregiver-cards-section {
    margin-top: 32px !important;
}

.caregiver-card {
    background-color: #FFFFFF !important;
    border: 1px solid #DDD7D7 !important;
    border-radius: 9px !important;
    padding: 16px 24px !important;
}

.caregiver-image {
    width: 57px !important;
    height: 57px !important;
    border-radius: 10px !important;
    object-fit: cover !important;
}

.caregiver-card-top-section {
    display: flex;
    justify-content: space-between;
}

.caregiver-name-and-title {
    width: 50%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.caregiver-name-and-title p:first-child {
    font-weight: 600;
    font-size: 16px;
}

.caregiver-name-and-title p:last-child {
    font-weight: initial;
    margin-top: 16px;
}

.caregiver-rate {
    color: #642D92;
    font-size: 24px;
    width: 30%;
}

.caregiver-card-description p {
    text-align: left;
    font-size: 18px;
    font-weight: 200;
    margin: 32px 0 !important;
}

.caregiver-card-details {
    display: flex;
    justify-content: space-between;
}

.caregiver-card-location, .caregiver-card-speciality {
    display: flex;
    color: #642D92;
}

.caregiver-card-location p {
    margin-left: 16px;
    font-size: 16px;
    font-weight: 200;
}

.hire-me-button {
    width: 100%;
    margin-top: 32px;
    border-radius: 10px !important;
    border: 1px solid #707070 !important;
    background-color: #FFFFFF !important;
    color: #0A0909 !important;
    transition: .5s linear ease-in;
}

.hire-me-button:hover {
    border: 1px solid #642D92 !important;
    color: #642D92 !important;
}

.explore-more-button {
    margin-top: 32px;
    width: fit-content;
    align-self: center;
    border-radius: 5px !important;
    background-color: #DE1877 !important;
    font-size: 14px;
    font-weight: initial;
    padding: 16px 32px !important;
}

@media screen and (max-width: 768px) {
    .caregiver-list-section {
        padding: 32px 16px !important;
    }

    .caregiver-list-section-inner {
        width: 100%;
    }

    .caregiver-list-title-section {
        flex-direction: column;
        margin-bottom: 16px !important;
    }

    .caregiver-list-title {
        width: 100%;
        margin-bottom: 16px !important;
    }

    .caregiver-list-title-description {
        text-align: left;
    }

    .services-chips-section {
        gap: 16px;
        flex-wrap: wrap;
    }

    .services-chips-section .best-services-chip {
        width: 44% !important;
    }

    .caregiver-cards-section {
        margin-top: 16px !important;
    }

    .caregiver-card-parent .caregiver-card {
        margin-bottom: 0 !important;
    }

    .caregiver-card-parent {
        margin-bottom: 16px !important;
    }
}