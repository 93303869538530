.dashboardContents {
  padding: 20px;
  margin: auto;
  max-width: 1200px; /* Ensure enough space for DataGrid */
}

.adminDashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Make the page take the full height */
  background-color: #f5f5f5; /* Light background for contrast */
}

h2 {
  margin-bottom: 20px;
  color: #333333;
  text-align: center;
}

.error-message {
  color: red;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
}

.sectionSwitcher {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
}

.section-switcher button {
  text-transform: none;
  font-size: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-top: -30px;
}

.form div {
  width: 100%;
  max-width: 400px;
}

.form button {
  margin-top: 10px;
}

.MuiDataGrid-root {
  background: white;
}

.MuiDataGrid-row:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
