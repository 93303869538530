/* MpesaPaymentModal.css */

.modalsContents {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-width: 90%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 30px;
  text-align: center;
  outline: none;
}

.modalsContents .MuiTextField-root {
  margin-top: 20px;
}

.modalsContents .MuiButton-root {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #642D92;
  border-radius: 4px;
  text-transform: none;
}

.modalsContents .MuiButton-root:hover {
  background-color: #0056b3;
}

.error-text {
  color: #ff4d4d;
  font-size: 14px;
  margin-top: 10px;
}
